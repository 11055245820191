/* eslint-disable no-extend-native */
/* eslint-disable @typescript-eslint/method-signature-style */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
export const isNullOrEmpty = (
  input?: string | null | undefined,
): input is undefined => {
  return input == null || input.length === 0
}

export const isString = (input?: string | null): input is string => {
  return typeof input === 'string' && (input?.length ?? 0) > 0
}
