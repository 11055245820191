export type IconSizes = 'xxs' | 'xs' | 's' | 'm' | 'ml' | 'l'
export const getIconSize = (size: IconSizes) => {
  switch (size) {
    case 'l':
      return '32px'
    case 'ml':
      return '24px'
    case 'm':
      return '20px'
    case 's':
      return '16px'
    case 'xs':
      return '12px'
    case 'xxs':
      return '8px'
    default:
      return '16px'
  }
}
